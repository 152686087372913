@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./toast-ui-custom.css');

.excalidraw {
  label[title='Library'] {
    display: none;
  }
}

#canvas-preview-wrapper {
  * {
    font-variant-numeric: normal;
    font-feature-settings: normal;
  }
}

.border {
  @apply border-solid;
}

.form-label {
  padding: 0;

  a {
    @apply underline underline-offset-auto;
  }

  * {
    font-weight: initial;
  }

  b,
  strong {
    font-weight: bolder;
  }
}

.simple-wysiwyg-content {
  & * {
    font-family: 'Inter';
  }

  a {
    color: #177ddc;
  }

  &.widget-component {
    a {
      @apply underline underline-offset-auto;
    }

    p {
      @apply mb-0;
    }
  }

  img {
    @apply h-auto max-w-full;
  }

  ul {
    @apply list-disc pl-4;
  }

  ol {
    @apply list-decimal pl-4 mb-4;
  }

  p {
    @apply mb-3;
  }

  &--aksinyata {
    p:last-child {
      @apply mb-0;
    }

    ul,
    ol {
      @apply mb-2 list-outside;

      padding-left: 1.1rem;
    }

    li p {
      @apply mb-0;
    }
  }

  &.material-content p {
    @apply mb-3;
  }

  &.aksi-nyata p {
    @apply mb-2;
  }

  &.material-content li {
    @apply mb-2;
  }

  &.material-content a {
    @apply underline underline-offset-auto;

    color: #1d4ed8;
  }

  /* scoping for video-inspirasi page */

  &.video-inspirasi {
    p {
      @apply mb-4;
    }

    strong {
      font-weight: var(--font-weight-heading-sm);
      font-size: var(--size-font-heading-sm);
      line-height: var(--size-line-height-text-heading-sm);
      text-transform: capitalize;

      @apply block;
    }

    p strong {
      @apply -mb-3;
    }

    li,
    li p {
      @apply mb-0;
    }

    ul {
      @apply pl-7;
    }

    li {
      @apply text-subdued;
    }
  }
}

.row-dragging {
  background: #141414;
  border: 1px solid #ccc;
  display: grid;
  grid-template-columns: 1fr 2.5fr 3fr 2fr;
  align-items: center;
}

.row-dragging--post-test {
  grid-template-columns: 0.5fr 1.5fr 2fr 2fr 1fr;
}

.row-dragging td {
  color: #fff;
  position: relative;
  padding: 8px;
  overflow-wrap: break-word;
  text-align: left;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.widget-component {
  font-family: 'Inter';
  @apply bg-white min-h-[10px];
}

.widget-component > p:last-child {
  margin-bottom: 0;
}

.widget-component.widget-component__selected,
.widget-component.widget-component__error {
  position: relative;
}

div[data-widgetcomponent='ContentWrapper']
  .widget-component.widget-component__selected:before {
  content: ' ';
  pointer-events: none;
  position: absolute;
  left: -6px;
  top: -6px;
  display: block;
  height: calc(100% + 12px);
  width: calc(100% + 12px);
  --tw-border-opacity: 1;
  z-index: 1;
  @apply border-4 rounded border-dashed border-interactive-focused;
}

div[data-widgetcomponent='ContentWrapper']
  .widget-component.widget-component__selected:after {
  content: attr(data-widgetname);
  position: absolute;
  left: -6px;
  bottom: -26px;
  height: fit-content;
  width: fit-content;
  z-index: 98;
  font-style: normal;
  font-weight: normal;
  @apply bg-interactive-focused p-1 px-[6px] text-white rounded-b text-xs;
}

div[data-widgetcomponent='ContentWrapper']
  .widget-component.widget-component__error:before {
  content: ' ';
  pointer-events: none;
  position: absolute;
  left: -6px;
  top: -6px;
  display: block;
  height: calc(100% + 12px);
  width: calc(100% + 12px);
  --tw-border-opacity: 1;
  @apply border-2 border-solid rounded border-interactive-critical-default;
}

div[data-widgetcomponent='ContentWrapper']
  .widget-component.widget-component__selected.widget-component__error:before {
  @apply border-4 border-dashed;
}

div[data-widgetcomponent='ContentWrapper']
  .widget-component.widget-component__error:after {
  @apply bg-interactive-critical-default;
}

div[data-widgetcomponent='ContentWrapper']
  .widget-component.widget-component__error:before.widget-component__selected:before {
  @apply border-dashed;
}

.widget-component:not(
    .widget-component .widget-component,
    .widget-component--container,
    .widget-toolbox
  ) {
  @apply px-3;
}

.widget-component:not(
    .widget-component .widget-component,
    .widget-component--container
  ):last-child {
  @apply mb-4;
}

.widget-component.widget-component--container
  + .widget-component.widget-component--page-card {
  @apply -mt-1;
}

.widget-component.widget-component--container
  > .widget-component.widget-component--page-card:first-child {
  @apply mt-0;
}

.widget-toolbox {
  @apply transition-opacity cursor-move hover:opacity-80;
}

.widget-toolbox--disabled {
  @apply opacity-50 pointer-events-none grayscale-[100%];
}

div[data-widgetcomponent='ContentWrapper']
  > .widget-component.widget-component--page-card:first-child {
  @apply mt-4;
}

.widget-component--date-picker div p,
.widget-component--checkbox-group div p {
  @apply m-0;
}

.widget-component--date-picker [data-cid='DateInput'] {
  @apply w-full;
}

.widget-component--select ul {
  @apply mb-0 pl-0;
}

.widget-component--select div p {
  @apply mb-0;
}

.widget-component--text-input {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.widget-component--text p {
  @apply last:mb-0 first:mt-0 !my-1;
}

.widget-rich-text-editor .ql-toolbar {
  @apply rounded-tl rounded-tr;
}

.widget-rich-text-editor .ql-container {
  @apply rounded-bl rounded-br;
}

.widget-rich-text-editor.widget-rich-text-editor__error .ql-toolbar {
  @apply border-[#E0061F];
}

.widget-rich-text-editor.widget-rich-text-editor__error .ql-container {
  @apply border-[#E0061F];
}

.ant-form-item-has-error .toastui-editor-defaultUI {
  @apply border-[#E0061F];
}

.toastui-editor-defaultUI,
.toastui-editor-defaultUI-toolbar {
  @apply !rounded-none;
}

.toastui-editor-defaultUI-toolbar {
  @apply !px-2;
}

.toastui-editor-popup-body ul li > * {
  @apply text-default;
}
