.toastui-editor-defaultUI, .toastui-editor-defaultUI-toolbar {
  @apply !rounded-none;
}

.toastui-editor-defaultUI-toolbar {
  @apply !px-2
}

.toastui-editor-popup-body ul li > * {
  @apply text-default;
}

.toastui-editor-contents h1,
.toastui-editor-contents h2,
.toastui-editor-contents h3,
.toastui-editor-contents h4,
.toastui-editor-contents h5,
.toastui-editor-contents h6,
{
  @apply !border-none;
}

.toastui-editor-contents ol > li::before
{
  @apply text-default;
}

.toastui-editor-contents ul > li::before
{
  @apply bg-icon-default;
}